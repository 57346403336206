var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopUp',{attrs:{"show":_vm.modal},on:{"hideModal":_vm.hideModal}},[_c('ValidationObserver',{ref:"observer",staticClass:"exhibition",attrs:{"tag":"div","id":"scroll-block"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('header',{staticClass:"exhibition__header"},[_c('h3',{staticClass:"exhibition__header_title"},[_vm._v(_vm._s(_vm.exhibition.id ? "Edit" : "Add")+" Exhibition")]),_c('div',{staticClass:"exhibition__header_actions"},[(_vm.item)?_c('BaseButton',{staticClass:"button--sm button--outline button--uppercase",attrs:{"text":"Delete Exhibition"},on:{"click":function($event){return _vm.$emit('removeExhibition', _vm.exhibition)}}}):_vm._e(),_c('BaseButton',{staticClass:"button--sm button--green button--uppercase",attrs:{"text":"Save Changes","loading":_vm.changesLoading,"disabled":invalid ||
            _vm.exhibition.search_image === '' ||
            (_vm.exhibition.featured && _vm.exhibition.home_page_image === '') ||
            !_vm.changed},on:{"click":_vm.saveExhibition}})],1)]),_c('section',{staticClass:"form"},[(_vm.exhibition.status)?_c('div',{staticClass:"form__header"},[_c('SimpleToggle',{attrs:{"placeholder":"Featured","disabled":invalid ||
            _vm.exhibition.search_image == '' ||
            _vm.exhibition.home_page_image == '' ||
            _vm.exhibition.copyright == '' ||
            !_vm.exhibition.status,"selected":+_vm.exhibition.featured,"items":[
            { id: '1', title: 'Active' },
            { id: '0', title: 'Inactive' } ]},on:{"input":function($event){_vm.exhibition.featured = $event}},model:{value:(_vm.exhibition.featured),callback:function ($$v) {_vm.$set(_vm.exhibition, "featured", $$v)},expression:"exhibition.featured"}}),(!_vm.exhibition.status)?_c('p',{staticClass:"form__header_notification"},[_vm._v(" Please make this exhibition Enabled before using this option ")]):_vm._e(),(!_vm.exhibition.home_page_image)?_c('p',{staticClass:"form__header_notification"},[_vm._v(" Please add a homepage image before using this option ")]):_vm._e(),(!_vm.exhibition.copyright)?_c('p',{staticClass:"form__header_notification"},[_vm._v(" Please fill in the copyright field before using this option ")]):_vm._e()],1):_vm._e()]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Exhibition Information")]),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Title","required":true,"validateError":errors[0] || _vm.serverError.title,"element":_vm.exhibition.title},on:{"input":function($event){_vm.exhibition.title = $event}},model:{value:(_vm.exhibition.title),callback:function ($$v) {_vm.$set(_vm.exhibition, "title", $$v)},expression:"exhibition.title"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true }}},[_c('BaseSelect',{attrs:{"placeholder":"Exhibition Type","selected":_vm.exhibition.exhibition_type_id,"items":_vm.exhibition_types,"required":true},on:{"input":function($event){_vm.exhibition.exhibition_type_id = $event.id}},model:{value:(_vm.exhibition.exhibition_type_id),callback:function ($$v) {_vm.$set(_vm.exhibition, "exhibition_type_id", $$v)},expression:"exhibition.exhibition_type_id"}})],1),_c('div',{staticClass:"form__row_col form__row_col--side"},[_c('Toggle',{attrs:{"placeholder":"Active Toggle","disabled":invalid || _vm.exhibition.search_image === '',"selected":+_vm.exhibition.status,"items":[
              { id: '1', title: 'Active' },
              { id: '0', title: 'Inactive' } ]},on:{"input":function($event){_vm.exhibition.status = $event}},model:{value:(_vm.exhibition.status),callback:function ($$v) {_vm.$set(_vm.exhibition, "status", $$v)},expression:"exhibition.status"}})],1)],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ required: true, path: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Path","required":true,"validateError":errors[0] || _vm.serverError.slug,"element":_vm.exhibition.slug},on:{"input":function($event){_vm.exhibition.slug = $event}},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('span',{staticClass:"form-control__simple-text"},[_vm._v(" Example: "+_vm._s((_vm.url + "/exhibitions/" + (_vm.exhibition.slug || "path")))+" ")])]},proxy:true}],null,true),model:{value:(_vm.exhibition.slug),callback:function ($$v) {_vm.$set(_vm.exhibition, "slug", $$v)},expression:"exhibition.slug"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Address","validateError":errors[0],"element":_vm.exhibition.address},on:{"input":function($event){_vm.exhibition.address = $event}},model:{value:(_vm.exhibition.address),callback:function ($$v) {_vm.$set(_vm.exhibition, "address", $$v)},expression:"exhibition.address"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col form__row_col--side"},[_c('DatePicker',{attrs:{"placeholder":"Start Date","required":true,"validateError":_vm.serverError.start_date,"value":_vm.exhibition.start_date},on:{"input":function($event){_vm.exhibition.start_date = $event}},model:{value:(_vm.exhibition.start_date),callback:function ($$v) {_vm.$set(_vm.exhibition, "start_date", $$v)},expression:"exhibition.start_date"}})],1),_c('div',{staticClass:"form__row_col form__row_col--side"},[_c('DatePicker',{attrs:{"placeholder":"End Date","required":true,"validateError":_vm.serverError.end_date,"startDate":_vm.exhibition.start_date,"value":_vm.exhibition.end_date},on:{"input":function($event){_vm.exhibition.end_date = $event}},model:{value:(_vm.exhibition.end_date),callback:function ($$v) {_vm.$set(_vm.exhibition, "end_date", $$v)},expression:"exhibition.end_date"}})],1)]),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ min: 2, max: 65000 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('Editor',{attrs:{"placeholder":"Exhibition Schedule","validateError":errors[0],"value":_vm.exhibition.schedule,"toolbar":['bold', 'italic']},on:{"input":function($event){_vm.exhibition.schedule = $event}},model:{value:(_vm.exhibition.schedule),callback:function ($$v) {_vm.$set(_vm.exhibition, "schedule", $$v)},expression:"exhibition.schedule"}})]}}],null,true)})],1)]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Exhibition Description")]),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ max: 65535 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseTextarea',{attrs:{"placeholder":"Description","validateError":errors[0],"element":_vm.exhibition.description},on:{"input":function($event){_vm.exhibition.description = $event}},model:{value:(_vm.exhibition.description),callback:function ($$v) {_vm.$set(_vm.exhibition, "description", $$v)},expression:"exhibition.description"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ max: 65535 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseTextarea',{attrs:{"placeholder":"Homepage Description","validateError":errors[0],"element":_vm.exhibition.home_description},on:{"input":function($event){_vm.exhibition.home_description = $event}},model:{value:(_vm.exhibition.home_description),callback:function ($$v) {_vm.$set(_vm.exhibition, "home_description", $$v)},expression:"exhibition.home_description"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Button Text","validateError":errors[0],"element":_vm.exhibition.button_text},on:{"input":function($event){_vm.exhibition.button_text = $event}},model:{value:(_vm.exhibition.button_text),callback:function ($$v) {_vm.$set(_vm.exhibition, "button_text", $$v)},expression:"exhibition.button_text"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Button URL","validateError":errors[0],"element":_vm.exhibition.button_url},on:{"input":function($event){_vm.exhibition.button_url = $event}},model:{value:(_vm.exhibition.button_url),callback:function ($$v) {_vm.$set(_vm.exhibition, "button_url", $$v)},expression:"exhibition.button_url"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col form__row_col--side"},[_c('Toggle',{attrs:{"placeholder":"Safety Protocol","selected":+_vm.exhibition.safety_protocol,"items":[
              { id: '1', title: 'Active' },
              { id: '0', title: 'Inactive' } ]},on:{"input":function($event){_vm.exhibition.safety_protocol = $event}},model:{value:(_vm.exhibition.safety_protocol),callback:function ($$v) {_vm.$set(_vm.exhibition, "safety_protocol", $$v)},expression:"exhibition.safety_protocol"}})],1),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"RSVP Button URL","validateError":errors[0],"element":_vm.exhibition.button_rsvp_url},on:{"input":function($event){_vm.exhibition.button_rsvp_url = $event}},model:{value:(_vm.exhibition.button_rsvp_url),callback:function ($$v) {_vm.$set(_vm.exhibition, "button_rsvp_url", $$v)},expression:"exhibition.button_rsvp_url"}})]}}],null,true)})],1),(+_vm.exhibition.safety_protocol === 1)?_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{
            required: true,
            min: 2,
            max: 65535,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('Editor',{attrs:{"placeholder":"Safety Protocol Information","required":true,"validateError":errors[0],"value":_vm.exhibition.safety_protocol_info,"toolbar":[
              'heading',
              'bold',
              'italic',
              'underline',
              'link',
              'blockQuote',
              '|',
              'alignment',
              'numberedList',
              'bulletedList' ]},on:{"input":function($event){_vm.exhibition.safety_protocol_info = $event}},model:{value:(_vm.exhibition.safety_protocol_info),callback:function ($$v) {_vm.$set(_vm.exhibition, "safety_protocol_info", $$v)},expression:"exhibition.safety_protocol_info"}})]}}],null,true)})],1):_vm._e()]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Exhibition Images")]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col form__row_col--side form__row_col--bordered"},[_c('span',{staticClass:"form__row_label"},[_vm._v("Search Image"),_c('span',{staticClass:"form__row_label_required"},[_vm._v("*")])]),_c('DragAndDropImage',{staticClass:"drag-and-drop--full-width",attrs:{"id":"search","size":"15","image":_vm.exhibition.search_image,"v-model":_vm.exhibition.search_image},on:{"input":function($event){_vm.exhibition.search_image = $event.image;
              $event.removed ? _vm.removedImages.push($event.removed) : false;},"removeImage":function($event){_vm.removedImages.push($event);
              _vm.exhibition.search_image = '';}}})],1),_c('div',{staticClass:"form__row_col form__row_col--side form__row_col--bordered"},[_c('span',{staticClass:"form__row_label"},[_vm._v("Header Image ")]),_c('DragAndDropImage',{staticClass:"drag-and-drop--full-width",attrs:{"id":"header","size":"15","image":_vm.exhibition.header_image,"v-model":_vm.exhibition.header_image},on:{"input":function($event){_vm.exhibition.header_image = $event.image;
              $event.removed ? _vm.removedImages.push($event.removed) : false;},"removeImage":function($event){_vm.removedImages.push($event);
              _vm.exhibition.header_image = '';}}})],1)]),_c('div',{staticClass:"form__row form__row--bordered"},[_c('span',{staticClass:"form__row_label"},[_vm._v(" Homepage Image "),(_vm.exhibition.featured)?_c('span',{staticClass:"form__row_label_required"},[_vm._v("*")]):_vm._e()]),_c('DragAndDropImage',{staticClass:"drag-and-drop--full-width",attrs:{"id":"home","size":"15","image":_vm.exhibition.home_page_image,"v-model":_vm.exhibition.home_page_image},on:{"input":function($event){_vm.exhibition.home_page_image = $event.image;
            $event.removed ? _vm.removedImages.push($event.removed) : false;},"removeImage":function($event){_vm.removedImages.push($event);
            _vm.exhibition.home_page_image = '';}}})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ required: _vm.exhibition.featured, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Copyright","required":_vm.exhibition.featured,"validateError":errors[0] || _vm.serverError.copyright,"element":_vm.exhibition.copyright},on:{"input":function($event){_vm.exhibition.copyright = $event}},model:{value:(_vm.exhibition.copyright),callback:function ($$v) {_vm.$set(_vm.exhibition, "copyright", $$v)},expression:"exhibition.copyright"}})]}}],null,true)})],1)]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Catalog")]),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Catalog Title","validateError":errors[0],"element":_vm.exhibition.catalog_title},on:{"input":function($event){_vm.exhibition.catalog_title = $event}},model:{value:(_vm.exhibition.catalog_title),callback:function ($$v) {_vm.$set(_vm.exhibition, "catalog_title", $$v)},expression:"exhibition.catalog_title"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ max: 65535 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseTextarea',{attrs:{"placeholder":"Catalog Description","validateError":errors[0],"element":_vm.exhibition.catalog_description},on:{"input":function($event){_vm.exhibition.catalog_description = $event}},model:{value:(_vm.exhibition.catalog_description),callback:function ($$v) {_vm.$set(_vm.exhibition, "catalog_description", $$v)},expression:"exhibition.catalog_description"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row form__row--bordered"},[_c('span',{staticClass:"form__row_label"},[_vm._v("Catalog Image")]),_c('DragAndDropImage',{staticClass:"drag-and-drop--full-width",attrs:{"id":"catalog","size":"15","image":_vm.exhibition.catalog_image,"v-model":_vm.exhibition.catalog_image},on:{"input":function($event){_vm.exhibition.catalog_image = $event.image;
            $event.removed ? _vm.removedImages.push($event.removed) : false;},"removeImage":function($event){_vm.removedImages.push($event);
            _vm.exhibition.catalog_image = '';}}})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Catalog Button Text","validateError":errors[0],"element":_vm.exhibition.catalog_button_text},on:{"input":function($event){_vm.exhibition.catalog_button_text = $event}},model:{value:(_vm.exhibition.catalog_button_text),callback:function ($$v) {_vm.$set(_vm.exhibition, "catalog_button_text", $$v)},expression:"exhibition.catalog_button_text"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Catalog Button URL","validateError":errors[0],"element":_vm.exhibition.catalog_button_url},on:{"input":function($event){_vm.exhibition.catalog_button_url = $event}},model:{value:(_vm.exhibition.catalog_button_url),callback:function ($$v) {_vm.$set(_vm.exhibition, "catalog_button_url", $$v)},expression:"exhibition.catalog_button_url"}})]}}],null,true)})],1)]),_vm._l((_vm.exhibition.sponsors),function(sponsor,id){return _c('section',{key:id,staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v(" Sponsor "+_vm._s(id + 1)+" "),_c('IconButton',{staticClass:"exhibition__sponsor-button button--admin button--xsm",attrs:{"text":"Remove Sponsor"},on:{"click":function($event){return _vm.removeSponsor(id)}}},[_c('IconClose',{attrs:{"width":"9","height":"9"}})],1)],1),_c('div',{staticClass:"form__row form__row--align-start"},[_c('div',{staticClass:"form__row_col form__row_col--side form__row_col--bordered"},[_c('span',{staticClass:"form__row_label"},[_vm._v("Sponsor Image")]),_c('DragAndDropImage',{staticClass:"drag-and-drop--full-width",attrs:{"id":("sponsor-" + id),"size":"15","canRemove":false,"image":sponsor.image,"v-model":sponsor.image},on:{"input":function($event){sponsor.image = $event.image;
              $event.removed ? _vm.removedImages.push($event.removed) : false;},"removeImage":function($event){_vm.removedImages.push($event);
              sponsor.image = '';}}})],1),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Sponsor URL","validateError":errors[0],"element":sponsor.url},on:{"input":function($event){sponsor.url = $event}},model:{value:(sponsor.url),callback:function ($$v) {_vm.$set(sponsor, "url", $$v)},expression:"sponsor.url"}})]}}],null,true)})],1)])}),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v(" Sponsor "),_c('IconButton',{staticClass:"exhibition__sponsor-button button--admin button--xsm",attrs:{"text":"Add Sponsor"},on:{"click":function($event){return _vm.addSponsor(_vm.sponsor)}}},[_c('IconPlus',{attrs:{"width":"12","height":"12"}})],1)],1),_c('div',{staticClass:"form__row form__row--align-start"},[_c('div',{staticClass:"form__row_col form__row_col--side form__row_col--bordered",class:{ 'form__row_col--error': _vm.sponsor.error }},[_c('span',{staticClass:"form__row_label"},[_vm._v("Sponsor Image")]),_c('DragAndDropImage',{staticClass:"drag-and-drop--full-width",attrs:{"id":"add-sponsor","size":"15","image":_vm.sponsor.image,"v-model":_vm.sponsor.image},on:{"input":function($event){_vm.sponsor.image = $event.image;
              _vm.sponsor.error = false;
              $event.removed ? _vm.removedImages.push($event.removed) : false;},"removeImage":function($event){_vm.removedImages.push($event);
              _vm.sponsor.image = '';}}})],1),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Sponsor URL","validateError":errors[0],"element":_vm.sponsor.url},on:{"input":function($event){_vm.sponsor.url = $event}},model:{value:(_vm.sponsor.url),callback:function ($$v) {_vm.$set(_vm.sponsor, "url", $$v)},expression:"sponsor.url"}})]}}],null,true)}),(_vm.sponsor.error)?_c('span',{staticClass:"form__row_text form__row_text--error"},[_vm._v(" This field is required ")]):_vm._e()],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }